html {
  position: relative;
  min-height: 100%;
}
body {
 padding-top: 70px;
  /* Margin bottom by footer height */
  margin-bottom: 70px;
}
.logo {
	padding: 2em;
}

.footer {
  padding: 1em;
  position: absolute;
  bottom: 0;
  width: 100%;
  /* Set the fixed height of the footer here */
  height: 60px;
  background-color: #f5f5f5;
}

.float-left {
  float: left; 
}
.float-right { 
  float: right;
}
